import service from "./service";

export const diferenciais = {
    namespaced: true,
    state: () => ({
        diferenciais: ""
    }),
    mutations: {
        setDiferenciais(state, data) {
            state.diferenciais = data;
        },
    },
    actions: {
        async searchCompanyDifferentials({ state, commit, rootState }) {
            commit('setCarregando', true, { root: true });
            try {
                const response = await service.searchCompanyDifferentials();
                const { items } = await response.data;
                if (items.length > 0) {
                    commit('setDiferenciais', items[0].diferenciais)
                }
                commit('setCarregando', false, { root: true });
            } catch (error) {
                commit('setCarregando', false, { root: true });
                commit('setAlerta', "erroPadrao", { root: true });
            }
        }
    },
    getters: {
        getDiferenciais(state, getters, rootState) {
            return state.diferenciais
        },
    }
}