<template>
    <Navbar></Navbar>
    <Alerta></Alerta>
    <Banner v-if="this.$route.name != 'Home' && this.$route.name != 'Reserva' && this.$route.name != 'Finalizar Reserva'">
    </Banner>

    <div :class="this.$route.name != 'Home' ? 'container' : 'container-fluid'" id="">
        <div class="row" :class="this.$route.name != 'Home' ? '' : 'p-0 position-relative d-flex justify-content-center'">
            <router-view />
        </div>
    </div>

    <div v-if="this.$route.name != 'Reserva'" class="container-fluid my-5" id="lojas">
        <Lojas></Lojas>
    </div>

    <div v-if="this.$route.name != 'Reserva'" class="container-fluid my-5" id="contato">
        <Contato></Contato>
    </div>

    <Mapa v-if="this.$route.name != 'Reserva'"></Mapa>

    <Rodape></Rodape>
    
    <ScrollTop></ScrollTop>
</template>

<script>
import { mapActions } from "vuex";
import Navbar from "./components/navbar.vue";
import Banner from "./components/banner.vue";
import Lojas from "./components/lojas.vue";
import Contato from "./components/contato.vue";
import Mapa from "./components/mapa.vue";
import Rodape from "./components/rodape.vue";
import ScrollTop from "./components/scrollTop.vue";
import Alerta from "./components/alerta.vue";


export default {
    components: {
        Navbar,
        Banner,
        Lojas,
        Contato,
        Mapa,
        Rodape,
        ScrollTop,
        Alerta
    },
    mounted(){
        this.searchTelefones();
    },
    methods: {
        ...mapActions({
            searchTelefones: 'empresa/searchTelefones',
        }),
    }
}
</script>

<style>
.container-total {
    margin: 0;
    max-width: 100%;
    padding: 0;
}
</style>
