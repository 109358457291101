import service from "./service";

export const lojas = {
    namespaced: true,
    state: () => ({
        lojasRetirada: [],
        lojas: [],
    }),
    mutations: {
        setLojas(state, data) {
            state.lojas = data;
        },
        setLojasRetirada(state, data) {
            state.lojasRetirada = data.filter(loja => { return loja.id === 1 || loja.id === 3});
        },
    },
    actions: {
        async searchLojas({ state, commit, rootState }) {
            try {
                const response = await service.searchLojas();
                commit('setLojas', response.data.items);
            } catch (error) {
                commit('setAlerta', "erroPadrao", { root: true });
            }
        },

        async searchLojasRetirada({ state, commit, rootState }) {
            try {
                const response = await service.searchLojasRetirada();
                commit('setLojasRetirada', response.data.items);
            } catch (error) {
                commit('setAlerta', "erroPadrao", { root: true });
            }
        },
    },
    getters: {
        getLojasRetirada(state, getters, rootState) {
            return state.lojasRetirada
        },
        getLojas(state, getters, rootState) {
            return state.lojas
        },
    }
}