<template>
    <div class="container mt-5">
        <div class="row">
            <div v-if="getCarregando" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>

            <p v-if="cars.length === 0 && !getCarregando" class="text-muted">Nenhum resultado encontrado</p>
            
            <div v-else class="col-md-6 col-lg-4 mb-3" v-for="car in cars" :key="car.id">
                <div class="card">
                    <div class="card-body">
                        <div class="row d-flex justify-content-center">
                            <div class="col-12">
                                <div class="text-center">
                                    <h5 class="text-uppercase mb-3">Grupo {{ car.titulo }} - {{ car.categoria }}</h5>
                                    <h6 class="text-center"> {{ car.modelo }} </h6>
                                </div>
                            </div>
                            <div class="row" v-if="!car.informacoes.displayInfo">
                                <div class="col-12 d-flex justify-content-center my-sm-5">
                                    <div class="animate__animated"
                                        :class="!car.informacoes.displayInfo ? 'animate__flipInY' : ''">
                                        <img :src="`https://www.kingcar.com.br/uploads/tarifario/${car.imagem}`"
                                            class="img-fluid" />
                                    </div>
                                </div>
                                <p class="text-muted small text-center">* Sujeito a disponibilidade da agência</p>
                            </div>

                            <div class="row animate__animated" v-else>
                                <div class="col-12">
                                    <div class="row my-sm-3 animate__animated"
                                        :class="car.informacoes.displayInfo ? 'animate__flipInY' : ''">
                                        <div class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">

                                                <i class="bi bi-gear-wide-connected me-2 text-primary"></i>
                                                <span>{{ car.informacoes.motor }}</span>
                                            </div>
                                        </div>

                                        <div class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">
                                                <i class="small bi bi-pin-map-fill text-primary me-2"></i>
                                                <span>{{ car.informacoes.automatico == 1 ? 'Automático' : 'Manual' }}</span>
                                            </div>
                                        </div>

                                        <div class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">

                                                <i class="bi bi-door-open-fill me-2 text-primary"></i>
                                                <span>{{ car.informacoes.portas }}</span>
                                            </div>
                                        </div>

                                        <div class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">
                                                <i class="small bi bi-people-fill text-primary me-2"></i>
                                                <span>{{ car.informacoes.pessoas }}</span>
                                            </div>
                                        </div>

                                        <div class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">

                                                <i class="small bi bi-briefcase-fill text-primary me-2"></i>
                                                <span>{{ car.informacoes.malas }}</span>
                                            </div>
                                        </div>

                                        <div v-if="car.informacoes.fuel" class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">
                                                <i class="small bi bi-fuel-pump-fill text-primary me-2"></i>
                                                <span>{{ car.informacoes.fuel }}</span>
                                            </div>
                                        </div>

                                        <div v-if="car.informacoes.ar == 1" class="col-12 mb-2">
                                            <div class="bg-grey rounded p-2">
                                                <i class="small bi bi-snow text-primary me-2"></i>
                                                <span>Ar-Condicionado</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer" @click="() => car.informacoes.displayInfo = !car.informacoes.displayInfo">
                        <p v-if="!car.informacoes.displayInfo" class="text-primary text-center cursor-pointer m-0">Mais
                            detalhes</p>
                        <p v-else class="text-primary text-center cursor-pointer m-0">Menos detalhes</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import 'animate.css';
export default {
    name: 'Tarifário',
    components: {},
    computed: {
        ...mapGetters({
            cars: 'grupoCarro/getGruposCarros',
            getCarregando: 'getCarregando',
        })
    },
    mounted() {
        window.scroll({ top: 0 });
        this.searchGruposCarros();
    },
    data: () => ({

    }),
    methods: {
        ...mapMutations({
            exibirMaisDetalhes: 'grupoCarro/exibirMaisDetalhes',
        }),

        ...mapActions({
            searchGruposCarros: 'grupoCarro/searchGruposCarros',
        }),
    },
    created(){
        document.title = 'King Rent a Car - Grupo de carros'
    },
}
</script>

<style scoped>
.card {
    min-height: 450px;
}

.bg-grey {
    background-color: #d9d8da47 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.card-footer {
    background-color: transparent;
    border-top: none;
}
</style>