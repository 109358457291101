import router from "@/router";
import service from "./service";

export const protecao = {
    namespaced: true,
    state: () => ({
        protections: [],
        opcionais: [],
    }),
    mutations: {
        setProtections(state, data) {
            state.protections = data;
        },
        setOpcionais(state, data) {
            state.opcionais = data;
        },
    },
    actions: {
        async searchProtections({ state, commit, rootState }) {
            commit('setCarregando', true, { root: true });
            try {
                const response = await service.searchProtections();
                const { items } = await response.data;
                if (items.length > 0) {
                    commit('setProtections', items);
                }
                commit('setCarregando', false, { root: true });
            } catch (error) {
                commit('setCarregando', false, { root: true });
                commit('setAlerta', "erroPadrao", { root: true });
            }
        },
        async searchTariffsOptional({ state, commit, rootState }) {
            commit('setCarregando', true, { root: true });
            try {
                const response = await service.searchTariffsOptional();
                const { items } = await response.data;
                if (items.length > 0) {
                    commit('setOpcionais', items);
                }
                commit('setCarregando', false, { root: true });
            } catch (error) {
                commit('setCarregando', false, { root: true });
                commit('setAlerta', "erroPadrao", { root: true });
            }
        },
    },
    getters: {
        getProtections(state, getters, rootState) {
            return state.protections
        },
        getProtectionsCategoria(state, getters, rootState) {
            const protecaoCategoria = [];
            if (!rootState.reserva.formularioReserva) {
                router.push({ name: "Home"});
                return;
            }
            const tarifarioId = rootState.reserva.formularioReserva.tarifarioId;

            if(tarifarioId){
                const grupo = rootState.grupoCarro.gruposCarros.find((grupo) => { return grupo.id == tarifarioId });
                if(grupo){
                    const categoria = grupo.titulo;
                    
                    for (const protection of state.protections) {
                        let valor;
                        
                        for (const element of protection.safeProtection.protections) {
                            const result = element.categories.includes(categoria);
                            if(result){
                                valor = element.value;
                            }
                        }
                        const protecao = {
                            id: protection.id,
                            titulo: protection.titulo,
                            texto: protection.texto,
                            tipo: protection.tipo,
                            valor
                        }
                        protecaoCategoria.push(protecao)
                    }
                }
                
            }

            return protecaoCategoria
        },
        getOpcionais(state, getters, rootState) {
            return state.opcionais
        },
    }
}