<template>
    <button type="button" class="btn btn-primary" id="scrollTop" @click="subir" :class="scrollY ? 'fade show' : 'fade hide'" :disabled="!scrollY" ><i class="bi bi-shift-fill"></i></button>
</template>

<script>
//v-if="scrollY >= 200 ? true : false"

export default {
    name: "scrollTop",

    data() {
        return {
            scrollY: false
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },

    methods: {
        subir() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });

        },
        handleScroll() {
            if(window.scrollY >= 200){
                this.scrollY = true;
            }else{
                this.scrollY = false;
            }
        }
    },
}
</script>

<style scoped>
#scrollTop {
    position: fixed;
    right: 5%;
    bottom: 8%;
}
</style>