import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import ComoAlugar from '../views/ComoAlugar.vue'
import Institucional from '../views/Institucional.vue'
import Diferenciais from '../views/Diferenciais.vue'
import GrupoDeCarros from '../views/GrupoDeCarros.vue'
import Protecao from '../views/Protecao.vue'
import Terceirizacao from '../views/Terceirizacao.vue'
import Rac from '../views/Rac.vue'
import Reserva from '../views/Reserva.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/como-alugar',
    name: 'Como Alugar',
    component: ComoAlugar
  },
  {
    path: '/institucional',
    name: 'Institucional',
    component: Institucional
  },
  {
    path: '/diferenciais',
    name: 'Diferenciais',
    component: Diferenciais
  },
  {
    path: '/grupo-de-carros',
    name: 'Grupo de Carros',
    component: GrupoDeCarros
  },
  {
    path: '/protecao',
    name: 'Proteção',
    component: Protecao
  },
  {
    path: '/terceirizacao',
    name: 'Terceirização',
    component: Terceirizacao
  },
  {
    path: '/rac',
    name: 'RAC',
    component: Rac
  },
  {
    path: '/reserva',
    name: 'Reserva',
    component: Reserva,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
