import router from "@/router";
import service from "./service";

export const reserva = {
    namespaced: true,
    state: () => ({
        horariosRetirada: [],
        horariosDevolucao: [],
        formularioReserva: null
    }),
    mutations: {
        setHorariosRetirada(state, data) {
            state.horariosRetirada = data;
        },
        setHorariosDevolucao(state, data) {
            state.horariosDevolucao = data;
        },
        atualizarFormularioReserva(state, data) {
            if (state.formularioReserva == null) {
                state.formularioReserva = data;
            }else if(data == null){
                state.formularioReserva = data;
            }else{
                for (const key in data) {
                    state.formularioReserva[key] = data[key];
                }
            }
        }
    },
    actions: {
        async searchHorariosDisponiveisRetiradaIdLoja({ state, commit, rootState }, { idLoja, data }) {
            try {
                const response = await service.searchHorariosDisponiveisIdLoja(idLoja, data);
                commit('setHorariosRetirada', response.data);
            } catch (error) {
                commit('setAlerta', "erroPadrao", { root: true });
            }
        },

        async searchHorariosDisponiveisDevolucaoIdLoja({ state, commit, rootState }, { idLoja, data }) {
            try {
                const response = await service.searchHorariosDisponiveisIdLoja(idLoja, data);
                commit('setHorariosDevolucao', response.data);
            } catch (error) {
                commit('setAlerta', "erroPadrao", { root: true });
            }
        },

        async postLoadInfo({ state, commit, rootState }, payload) {
            commit('setCarregando', true, { root: true });
            try {
                const response = await service.postLoadInfo(payload);
                commit('atualizarFormularioReserva', response.data);
                commit('setCarregando', false, { root: true });
                router.push({ name: "Reserva" });
            } catch (error) {
                commit('setCarregando', false, { root: true });
                router.push({ name: "Home" });
                commit('setAlerta', "erroPadraoReserva", { root: true });
            }
        },

        async postLoadInfoCart({ state, commit, rootState }) {
            commit('setCarregando', true, { root: true });
            try {
                const response = await service.postLoadInfoCart(state.formularioReserva);
                commit('atualizarFormularioReserva', response.data);
                commit('setCarregando', false, { root: true });
            } catch (error) {
                commit('setAlerta', "erroPadraoReserva", { root: true });
                router.push({ name: "Home" });
                commit('setCarregando', false, { root: true });
            }

        },

        async postFinalize({ state, commit, rootState }) {
            commit('setCarregando', true, { root: true });
            try {
                await service.postFinalize(state.formularioReserva);
                commit('atualizarFormularioReserva', null);
                commit('setCarregando', false, { root: true });
                commit('setAlerta', "sucessoPadrao", { root: true });
                router.push({ name: "Home" });
            } catch (error) {
                const mensagem = []
                for (const texto of error.response.data.message) {
                    mensagem.push({
                        titulo: "Verifique as informações!",
                        status: "warning",
                        mensagem: texto
                    })
                }
                commit('setAlerta', mensagem, { root: true });
                commit('setCarregando', false, { root: true });
            }
        },
    },
    getters: {
        getHorariosRetirada(state, getters, rootState) {
            return state.horariosRetirada
        },
        getHorariosDevolucao(state, getters, rootState) {
            return state.horariosDevolucao
        },
        getFormularioReserva(state, getters, rootState) {
            return state.formularioReserva
        },
        getTotalDiaria(state, getters, rootState) {
            return state.totalDiaria
        },
    }
}