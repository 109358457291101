<template>
    <div class="container py-4">
        <div class="col-12">
            <div class="row d-flex justify-content-center mb-3">
                <img class="" id="img-rodape" src="./../assets/images/logo3.png" alt="">
            </div>
            <div class="text-center">
                <h6 class="mb-3">Copyright 2023 | Todos os direitos reservados à King Rent a Car</h6>
                <p class="bolder">Desenvolvido por dudhavix e Erickson</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "rodape"
}
</script>

<style scoped>
#img-rodape {
    width: 150px;
}
</style>