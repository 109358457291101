<template>
    <div class="d-flex justify-content-center">
        <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <div class="card bg-primary rounded-4">
                <div class="card-body">
                    <div class="row">
                        <h4 class="mb-3 text-white">Faça sua reserva aqui</h4>

                        <!-- RETIRADA -->
                        <div class="col-12 mb-3">
                            <div class="form-group">
                                <label for="floatingInput">Local da retirada</label>
                                <select v-model="formulario.retirada.local" class="form-control" name="" id="">
                                    <option value="null">Selecione a cidade</option>
                                    <option v-for="loja in lojasRetirada" :key="loja.id" :value="loja.id">{{
                                        loja.titulo }}</option>
                                </select>
                            </div>
                        </div>

                        <!-- DEVOLUÇÃO EM OUTRA LOJA? -->
                        <div class="col-12 mb-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch"
                                    id="flexSwitchCheckChecked" v-model="formulario.devolucaoOutraLoja"
                                    :checked="formulario.devolucaoOutraLoja">
                                <label class="form-check-label" for="flexSwitchCheckChecked">Devolução em outra
                                    loja</label>
                            </div>
                        </div>

                        <!-- DEVOLUÇÃO -->
                        <div v-if="formulario.devolucaoOutraLoja" class="col-12 mb-3">
                            <div class="form-group">
                                <label for="floatingInput">Local de devolução</label>
                                <select :disabled="formulario.devolucaoOutraLoja && formulario.retirada.local == ''"
                                    v-model="formulario.devolucao.local" class="form-control" name="" id="">
                                    <option value="null">Selecione a cidade</option>
                                    <option v-for="loja in lojasDevolucao" :key="loja.id" :value="loja.id">{{
                                        loja.titulo }}</option>
                                </select>
                            </div>
                        </div>

                        <!-- SELECIONAR DATA E HORÁRIO DE RETIRADA -->
                        <div class="col-md-6 mb-3">
                            <label for="floatingInput">Retirada</label>
                            <div class="input-group">
                                <input @change="carregarDataDevolucao" :disabled="!formulario.retirada.local"
                                    v-model="formulario.retirada.data" :min="hoje" :max="formulario.devolucao.data"
                                    type="date" class="form-control w-45" id="dataRetirada"
                                    title="Data e hora de retirada" placeholder="Data e hora da retirada">
                                <select @click="carregarHorariosDisponiveisRetirada"
                                    :disabled="!formulario.retirada.local || !formulario.retirada.data"
                                    v-model="formulario.retirada.hora" id="horaRetirada" class="form-select">
                                    <option value="">hh:mm</option>
                                    <option value="" v-if="horariosRetirada.length == 0 && formulario.retirada.data">
                                        Fechado
                                    </option>
                                    <option v-for="(horario, index) in horariosRetirada" :key="index" :value="horario">
                                        {{ horario }}</option>
                                </select>
                            </div>
                        </div>

                        <!-- SELECIONAR DATA E HORÁRIO DE DEVOLUÇÃO -->
                        <div class="col-md-6 mb-3">
                            <label for="floatingInput">Devolução</label>
                            <div class="input-group">
                                <input :disabled="!formulario.retirada.data" v-model="formulario.devolucao.data"
                                    :min="formulario.retirada.data" type="date" class="form-control w-45"
                                    id="dataRetirada" title="Data e hora de retirada"
                                    placeholder="Data e hora da retirada">
                                <select @click="carregarHorariosDisponiveisDevolucao"
                                    :disabled="!formulario.devolucao.local || !formulario.devolucao.data"
                                    v-model="formulario.devolucao.hora" id="horaRetirada" class="form-select">
                                    <option value="">hh:mm</option>
                                    <option value="" v-if="horariosDevolucao.length == 0 && formulario.devolucao.data">
                                        Fechado
                                    </option>
                                    <option v-for="(horario, index) in horariosDevolucao" :key="index" :value="horario">
                                        {{ horario }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-12 mb-4 d-grid gap-2">
                            <!-- <router-link @click="iniciarSolicitacaoReserva" to="/reserva"
                                :class="formularioPreenchido() ? '' : 'disabled'"
                                class="btn btn-primary"><span>Selecionar Veiculo</span></router-link> -->
                            <button v-if="!getCarregando" @click="iniciarSolicitacaoReserva"
                                :class="formularioPreenchido() ? '' : 'disabled'" class="btn btn-primary">Selecionar
                                Veiculo</button>
                            <button v-if="getCarregando" class="btn btn-primary" disabled>
                                <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                            </button>
                        </div>

                        <div class="col-12 mb-3 d-grid gap-2">
                            <a :href="whatsapp" target="_blank" class="btn btn-success"><i
                                    class="bi bi-whatsapp me-2"></i>Se preferir reserve via whatsapp</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import { linksRedesSociais } from "./../assets/constantes";
import moment from "moment";

export default {
    name: "formulario-reserva",
    computed: {
        ...mapGetters({
            lojasRetirada: 'lojas/getLojasRetirada',
            lojasDevolucao: 'lojas/getLojas',
            horariosRetirada: 'reserva/getHorariosRetirada',
            horariosDevolucao: 'reserva/getHorariosDevolucao',
            formularioReserva: 'reserva/getFormularioReserva',
            getCarregando: 'getCarregando',
        })
    },
    data() {
        return {
            hoje: '',
            formulario: {
                retirada: {
                    local: null,
                    data: null,
                    hora: '',
                },
                devolucao: {
                    local: null,
                    data: null,
                    hora: '',
                },
                totalDeDiasReservados: 0,
                devolucaoOutraLoja: false
            },
            whatsapp: linksRedesSociais.whatsapp,
        }
    },
    mounted() {
        this.hoje = moment().format('YYYY-MM-DD');
        this.searchLojasRetirada();
        this.searchLojasDevolucao();

        if (this.formularioReserva) {
            if (this.formularioReserva.localRetiradaId) {
                this.formulario.retirada.local = this.formularioReserva.localRetiradaId;
            }
            if (this.formularioReserva.localDevolucaoId) {
                this.formulario.devolucao.local = this.formularioReserva.localDevolucaoId;
            }
            if (this.formularioReserva.dataRetirada) {
                this.formulario.retirada.data = this.formularioReserva.dataRetirada;
            }
            if (this.formularioReserva.dataDevolucao) {
                this.formulario.devolucao.data = this.formularioReserva.dataDevolucao;
            }
            if (this.formularioReserva.horarioRetirada) {
                this.formulario.retirada.horalocal = this.formularioReserva.horarioRetirada;
            }
            if (this.formularioReserva.horarioDevolucao) {
                this.formulario.devolucao.horalocal = this.formularioReserva.horarioDevolucao;
            }
        }

    },
    methods: {
        ...mapMutations({
            atualizarFormularioReserva: 'reserva/atualizarFormularioReserva'
        }),

        ...mapActions({
            searchLojasRetirada: 'lojas/searchLojasRetirada',
            searchLojasDevolucao: 'lojas/searchLojas',
            searchHorariosDisponiveisRetiradaIdLoja: 'reserva/searchHorariosDisponiveisRetiradaIdLoja',
            searchHorariosDisponiveisDevolucaoIdLoja: 'reserva/searchHorariosDisponiveisDevolucaoIdLoja',
            postLoadInfo: 'reserva/postLoadInfo',
        }),

        carregarHorariosDisponiveisRetirada() {
            this.searchHorariosDisponiveisRetiradaIdLoja({
                idLoja: this.formulario.retirada.local,
                data: this.formulario.retirada.data.split("T")[0] == this.hoje ? moment().format() : moment(this.formulario.retirada.data.split("T")[0]).format()
            });

        },

        carregarHorariosDisponiveisDevolucao() {
            this.searchHorariosDisponiveisDevolucaoIdLoja({
                idLoja: this.formulario.devolucao.local,
                data: this.formulario.devolucao.data.split("T")[0] == this.hoje ? moment().format() : moment(this.formulario.devolucao.data.split("T")[0]).format()
            });
        },

        carregarDataDevolucao() {
            this.formulario.devolucao.data = moment(this.formulario.retirada.data).add(1, 'days').format('YYYY-MM-DD');
        },

        formularioPreenchido() {
            if (
                this.formulario.retirada.local &&
                this.formulario.devolucao.local &&
                this.formulario.retirada.data &&
                this.formulario.devolucao.data &&
                this.formulario.retirada.hora != "" &&
                this.formulario.devolucao.hora != ""
            ) {
                return true
            } else {
                return false
            }
        },

        async iniciarSolicitacaoReserva() {
            await this.postLoadInfo({
                localRetiradaId: this.formulario.retirada.local,
                localDevolucaoId: this.formulario.devolucao.local,
                dataRetirada: this.formulario.retirada.data.split("T")[0],
                dataDevolucao: this.formulario.devolucao.data.split("T")[0],
                horarioRetirada: this.formulario.retirada.hora,
                horarioDevolucao: this.formulario.devolucao.hora,
            });
        }
    },
    updated() {
        if (!this.formulario.devolucaoOutraLoja) {
            this.formulario.devolucao.local = this.formulario.retirada.local
        }
    }

}
</script>

<style scoped>
.form-floating {
    color: #d9d8da;
}

.bg-primary {
    background-color: #9F090B !important;
}

.w-45 {
    width: 45%;
}
</style>